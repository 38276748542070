"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const background = __importStar(require("./background"));
exports.background = background;
const borderRadius = __importStar(require("./border-radius"));
exports.borderRadius = borderRadius;
const borders = __importStar(require("./borders"));
exports.borders = borders;
const boxShadow = __importStar(require("./box-shadow"));
exports.boxShadow = boxShadow;
const dimensions = __importStar(require("./dimensions"));
exports.dimensions = dimensions;
const flex = __importStar(require("./flex"));
exports.flex = flex;
const grid = __importStar(require("./grid"));
exports.grid = grid;
const interaction = __importStar(require("./interaction"));
exports.interaction = interaction;
const layout = __importStar(require("./layout"));
exports.layout = layout;
const list = __importStar(require("./list"));
exports.list = list;
const opacity = __importStar(require("./opacity"));
exports.opacity = opacity;
const outline = __importStar(require("./outline"));
exports.outline = outline;
const overflow = __importStar(require("./overflow"));
exports.overflow = overflow;
const position = __importStar(require("./position"));
exports.position = position;
const resize = __importStar(require("./resize"));
exports.resize = resize;
const spacing = __importStar(require("./spacing"));
exports.spacing = spacing;
const text = __importStar(require("./text"));
exports.text = text;
const transform = __importStar(require("./transform"));
exports.transform = transform;
const transition = __importStar(require("./transition"));
exports.transition = transition;
exports.propTypes = Object.assign({}, background.propTypes, borderRadius.propTypes, borders.propTypes, boxShadow.propTypes, dimensions.propTypes, flex.propTypes, grid.propTypes, interaction.propTypes, layout.propTypes, list.propTypes, opacity.propTypes, outline.propTypes, overflow.propTypes, position.propTypes, resize.propTypes, spacing.propTypes, text.propTypes, transform.propTypes, transition.propTypes);
exports.propNames = Object.keys(exports.propTypes);
exports.propAliases = Object.assign({}, background.propAliases, borderRadius.propAliases, borders.propAliases, boxShadow.propAliases, dimensions.propAliases, flex.propAliases, grid.propAliases, interaction.propAliases, layout.propAliases, list.propAliases, opacity.propAliases, outline.propAliases, overflow.propAliases, position.propAliases, resize.propAliases, spacing.propAliases, text.propAliases, transform.propAliases, transition.propAliases);
exports.propValidators = Object.assign({}, background.propValidators, borderRadius.propValidators, borders.propValidators, boxShadow.propValidators, dimensions.propValidators, flex.propValidators, grid.propValidators, interaction.propValidators, layout.propValidators, list.propValidators, opacity.propValidators, outline.propValidators, overflow.propValidators, position.propValidators, resize.propValidators, spacing.propValidators, text.propValidators, transform.propValidators, transition.propValidators);
exports.propEnhancers = Object.assign({}, background.propEnhancers, borderRadius.propEnhancers, borders.propEnhancers, boxShadow.propEnhancers, dimensions.propEnhancers, flex.propEnhancers, grid.propEnhancers, interaction.propEnhancers, layout.propEnhancers, list.propEnhancers, opacity.propEnhancers, outline.propEnhancers, overflow.propEnhancers, position.propEnhancers, resize.propEnhancers, spacing.propEnhancers, text.propEnhancers, transform.propEnhancers, transition.propEnhancers);
