"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const cache = __importStar(require("./cache"));
const styles = __importStar(require("./styles"));
var box_1 = require("./box");
exports.default = box_1.default;
var split_props_1 = require("./utils/split-props");
exports.splitProps = split_props_1.default;
var split_box_props_1 = require("./utils/split-box-props");
exports.splitBoxProps = split_box_props_1.default;
var get_class_name_1 = require("./get-class-name");
exports.setClassNamePrefix = get_class_name_1.setClassNamePrefix;
var safeHref_1 = require("./utils/safeHref");
exports.configureSafeHref = safeHref_1.configureSafeHref;
var index_1 = require("./enhancers/index");
exports.background = index_1.background;
exports.borderRadius = index_1.borderRadius;
exports.borders = index_1.borders;
exports.boxShadow = index_1.boxShadow;
exports.dimensions = index_1.dimensions;
exports.flex = index_1.flex;
exports.interaction = index_1.interaction;
exports.layout = index_1.layout;
exports.list = index_1.list;
exports.opacity = index_1.opacity;
exports.overflow = index_1.overflow;
exports.position = index_1.position;
exports.spacing = index_1.spacing;
exports.text = index_1.text;
exports.transform = index_1.transform;
exports.propTypes = index_1.propTypes;
exports.propNames = index_1.propNames;
exports.propAliases = index_1.propAliases;
exports.propEnhancers = index_1.propEnhancers;
exports.hydrate = cache.hydrate;
function extractStyles() {
    const output = {
        cache: cache.entries(),
        styles: styles.getAll()
    };
    clearStyles();
    return output;
}
exports.extractStyles = extractStyles;
function clearStyles() {
    cache.clear();
    styles.clear();
}
exports.clearStyles = clearStyles;
